<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" offset-sm="0">
        <v-card id="userlistcard" :loading="loading">
          <v-list two-line>
            <h2 v-if="!!!userList">Keine Benutzer gefunden</h2>
            <v-list v-else v-for="(item, index) in userList" :key="index">
              <User :user="item" @delete="askDelete(index)" />
              <v-divider class="mx-4" vertical></v-divider>
            </v-list>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dlgDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Benutzer löschen?</v-card-title>
        <v-card-text>
          Soll der Benutzer
          <strong>{{ deleteMitarbeiterName }}</strong> wirklich gelöscht werden?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined text @click="dlgDelete = false"
            >Abbrechen</v-btn
          >
          <v-btn dark color="red darken-1" @click="deleteUser">
            <v-icon>mdi-delete</v-icon>Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import UserService from "@/services/UserService.js";
import User from "@/components/User.vue";

export default {
  name: "UserList",
  data: () => ({
    loading: false,
    dlgDelete: false,
    deleteItem: false,
    deleteMitarbeiterName: ""
  }),
  components: {
    User
  },
  methods: {
    askDelete(index) {
      this.deleteMitarbeiterName = this.getNameByIndex(index);
      this.dlgDelete = true;
      this.deleteItem = index;
    },
    deleteUser() {
      this.dlgDelete = false;

      UserService.delete(this.userList[this.deleteItem]._id)
        .then(() => {
          this.userList.splice(this.deleteItem, 1);
          this.$notify({
            group: "dataline",
            type: "info",
            title: "Benutzer gelöscht",
            text: "Benutzer " + this.deleteMitarbeiterName + " wurde gelöscht"
          });
          this.deleteMitarbeiterName = "";
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        });
    },
    getNameByIndex(index) {
      if (this.userList == null) {
        return "";
      } else
        return (
          this.userList[index].firstname + " " + this.userList[index].lastname
        );
    }
  },

  computed: {
    userList() {
      return this.$store.getters["user/getUserList"];
    }
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("user/fetchUserList")
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        }
      });
  }
};
</script>

<style scoped>
#userlistcard {
  margin: 20px;
}
</style>
