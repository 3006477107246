<template>
  <div>
    <v-list-item class="user">
      <v-list-item-icon>
        <v-avatar size="48" color="deep-purple">
          <img v-if="user.image" :src="user.image" />
          {{ getInitials }}
        </v-avatar>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-html="getName"> </v-list-item-title>
        <v-list-item-subtitle> {{ user.apps.roles }} 
          <v-chip :color="statusColor">
            {{ userActive }}
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>

      <div class="d-flex justify-end">
        <v-btn
          :to="{
            name: 'user-edit',
            params: { user: this.user },
          }"
          color="primary"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-spacer v-if="!currentUser" class="mr-5" />

        <v-btn color="error" @click="deleteItem" v-if="!currentUser">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "User",
  data: () => ({}),
  props: ["user"],
  methods: {
    deleteItem() {
      this.$emit("delete");
    },
  },
  computed: {
    loggedInUser(){
      return this.$store.getters["user/getUser"];
    },
    currentUser(){
      return this.loggedInUser._id === this.user._id;
    },
    getInitials() {
      let initials = "";
      if (this.user) {
        initials =
          this.user.firstname.substring(0, 1).toUpperCase() +
          this.user.lastname.substring(0, 1).toUpperCase();
      }
      return initials;
    },
    getName() {
      return this.user.firstname + " " + this.user.lastname;
    },
    userActive() {
      if(this.user.active === true){
        return "Aktiv";
      } else {
        return "Inaktiv"
      }
    },
    statusColor(){
      if(this.user.active === true){
        return "success";
      }
      else {
        return "error";
      }
    },
    getAdresse() {
      return (
        this.user.street +
        " " +
        this.user.streetnr +
        "<br/>" +
        this.user.plz +
        " " +
        this.user.city
      );
    },
  },
};
</script>

<style scoped>
.user:hover {
  background-color: #cbdfff;
}
.checkin {
  font-weight: bold;
  font-size: 1.2em;
  color: green;
}
.checkout {
  font-weight: bold;
  font-size: 1.2em;
  color: red;
}
</style>
